import { CIcon } from '~/icons/types'

export const ciMagnifyingGlass: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M10.5 4a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM2 10.5a8.5 8.5 0 1 1 15.176 5.262l4.531 4.53a1 1 0 0 1-1.414 1.415l-4.531-4.531A8.5 8.5 0 0 1 2 10.5Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'magnifying-glass',
  type: 'regular'
}
