






























































































































































import { Option } from '~/models/shared/types'
import {
  computed,
  defineNuxtComponent,
  PropType,
  ref,
  onBeforeUnmount,
  onMounted,
  nextTick
} from '~/utils/nuxt3-migration'
import CDropdown from '~/components/shared/configurable/dropdown/CDropdown.vue'
import { ciMagnifyingGlass } from '~/icons/source/regular/magnifying-glass'
import { formatNumber } from '~/utils/number'
import { useId } from '~/compositions/id'

import { OptionGroup } from '~/components/shared/configurable/form/select/types'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineNuxtComponent({
  props: {
    container: {
      type: String,
      default: null
    },
    q: {
      type: String,
      required: true
    },
    searchPlaceholder: {
      type: String,
      required: true
    },
    filteredOptionGroups: {
      type: Array as PropType<OptionGroup[]>,
      required: true
    },
    filteredOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    dropdownClass: {
      type: String,
      required: true
    },
    noResultsMessage: {
      type: String,
      default: ''
    },
    searchable: {
      type: Boolean,
      required: true
    },
    selectedOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    multiSelect: {
      type: Boolean,
      required: true
    },
    internalValue: {
      type: [Array, Number, String],
      default: null
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    autoSize: {
      type: Boolean,
      default: true
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      required: true
    },
    showCount: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { createRandomId } = useId()

    const dropdownRef = ref<typeof CDropdown>()
    const searchInputRef = ref<HTMLInputElement>()
    const inputId = createRandomId()

    const dropdownContentClasses = computed(() => {
      const classes = []

      if (props.size === 'lg') {
        classes.push('tw-gap-4 tw-py-3')
      } else if (props.size === 'sm') {
        classes.push('tw-gap-2 tw-py-2')
      } else {
        classes.push('tw-gap-3 tw-py-3')
      }

      return classes
    })

    const dropdownContentSectionClasses = computed(() => {
      const classes = []

      if (props.size === 'lg') {
        classes.push('tw-px-4')
      } else if (props.size === 'sm') {
        classes.push('tw-px-3')
      } else {
        classes.push('tw-px-3')
      }

      return classes
    })

    const distance = computed(() => {
      switch (props.size) {
        case 'sm':
          return '2'
        case 'md':
          return '4'
        case 'lg':
          return '8'
        default:
          return '4'
      }
    })

    onMounted(() => {
      window.addEventListener('keydown', onKeyDown)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', onKeyDown)
    })

    function optionInSelected(option: Option) {
      return props.selectedOptions.some(
        selectedOption => selectedOption.value === option.value
      )
    }

    function onOptionClick(option: Option) {
      if (option.disabled) {
        return
      }

      if (props.multiSelect) {
        if (optionInSelected(option)) {
          emit(
            'multi-select',
            props.internalValue.filter(v => v !== option.value)
          )
        } else {
          emit('multi-select', [...props.internalValue, option.value])
        }
      } else {
        emit('select', option.value)

        if (dropdownRef.value) {
          dropdownRef.value.hide()
        }
      }
    }

    function onMultiselectCheckboxChange(value: any) {
      emit('multi-select', value)
    }

    function getOptionClasses(option: Option) {
      const classes = []

      if (optionInSelected(option)) {
        classes.push('tw-text-blue-500')
      }

      if (props.size === 'lg') {
        classes.push('tw-py-2.5 tw-text-lg')
      } else {
        classes.push('tw-py-1.5 tw-text-base')
      }

      if (option.disabled) {
        classes.push('tw-cursor-default tw-opacity-40')
      } else {
        classes.push('tw-cursor-pointer hover:tw-text-blue-500')
      }

      return classes
    }

    function onKeyDown(event: KeyboardEvent) {
      if (!dropdownRef.value) {
        return
      }

      if (event.key === 'Escape') {
        dropdownRef.value.hide()
      }
    }

    function onDropdownShow() {
      emit('show')
    }

    function onDropdownHidden() {
      emit('hidden')
    }

    async function onDropdownShown() {
      if (props.searchable) {
        await nextTick()
        // FIXME: Using a normal ref here doesn't work because the ref is not yet available when the dropdown is fully shown
        const inputEl = document.getElementById(inputId.value)

        if (inputEl) {
          inputEl.focus()
        }
      }
    }

    function onSearchInput(event: Event) {
      const text = (event.target as HTMLInputElement).value

      emit('search', text)
    }

    return {
      ciMagnifyingGlass,
      dropdownRef,
      searchInputRef,
      dropdownContentClasses,
      dropdownContentSectionClasses,
      distance,
      inputId,
      onDropdownShow,
      onDropdownShown,
      onDropdownHidden,
      formatNumber,
      getOptionClasses,
      optionInSelected,
      onOptionClick,
      onSearchInput,
      onMultiselectCheckboxChange
    }
  },
  i18n: defineComponentTranslations({
    rest_of_options: {
      en: 'Rest of options',
      el: 'Υπόλοιπες επιλογές'
    }
  })
})
