




































































import CCustomSelectHeader from '~/components/shared/configurable/form/select/custom/CCustomSelectHeader.vue'
import { Option } from '~/models/shared/types'
import CCustomSelectDropdown from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdown.vue'
import CCustomSelectNativeSelect from '~/components/shared/configurable/form/select/custom/CCustomSelectNativeSelect.vue'
import { textExistsInString } from '~/utils/string'
import {
  vue3Model,
  defineNuxtComponent,
  PropType,
  computed,
  ref
} from '~/utils/nuxt3-migration'
import { SelectSize } from '~/models/app/select'

import { OptionGroup } from '~/components/shared/configurable/form/select/types'
import { useUserAgent } from '~/compositions/user-agent'

export default defineNuxtComponent({
  model: vue3Model,
  components: {
    CCustomSelectHeader,
    CCustomSelectDropdown,
    CCustomSelectNativeSelect
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    headerClasses: {
      type: String,
      default: ''
    },
    headerPrefix: {
      type: String,
      default: ''
    },
    dropdownClass: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Array, Object],
      default: null
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: ''
    },
    searchable: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array as PropType<Option[]>,
      default() {
        return []
      }
    },
    optionGroups: {
      type: Array as PropType<OptionGroup[]>,
      default() {
        return []
      }
    },
    valueField: {
      type: String,
      default: 'value'
    },
    textField: {
      type: String,
      default: 'text'
    },
    size: {
      type: String as PropType<SelectSize>,
      default: 'md'
    },
    noResultsMessage: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: null
    },
    warning: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: false
    },
    autoSize: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    inGroup: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { isMobile, isTablet } = useUserAgent()

    const q = ref('')
    const internalDropdownOpen = ref(false)
    const customSelectRef = ref<HTMLDivElement>()

    const internalValue = computed(() => {
      if (!props.modelValue) {
        return []
      }

      return Array.isArray(props.modelValue)
        ? props.modelValue
        : [props.modelValue]
    })

    const internalOptions = computed(() => {
      if (props.valueField || props.textField) {
        return props.options.map(mapToOption)
      }

      return props.options
    })

    const flatOptionGroupsOptions = computed(() =>
      props.optionGroups.flatMap(group => group.options)
    )

    const allOptions = computed(() =>
      flatOptionGroupsOptions.value.concat(internalOptions.value)
    )

    const selectedOptions = computed(() => {
      if (!internalValue.value) {
        return []
      }

      return allOptions.value.filter((option: Option) =>
        internalValue.value.includes(option.value)
      )
    })

    const filteredOptions = computed(() => {
      if (q.value) {
        return internalOptions.value.filter((option: Option) =>
          textExistsInString(q.value, option.text)
        )
      }

      return internalOptions.value
    })

    const filteredOptionGroups = computed(() => {
      if (!q.value || !props.optionGroups.length) {
        return props.optionGroups
      }

      // TODO: maybe perform this in a more elegant way
      const filteredOptionGroups: OptionGroup[] = []

      props.optionGroups.forEach(group => {
        const filteredGroupOptions = group.options.filter((option: Option) =>
          textExistsInString(q.value, option.text)
        )
        if (filteredGroupOptions.length > 0) {
          filteredOptionGroups.push({
            ...group,
            options: filteredGroupOptions
          })
        }
      })

      return filteredOptionGroups
    })

    const disabledOptions = computed(() => {
      const disabled = new Set<Option['value']>()

      for (const option of internalOptions.value) {
        option.disabled && disabled.add(option)
      }

      return disabled
    })

    const nothingSelected = computed(
      () =>
        internalValue.value === null ||
        (Array.isArray(internalValue.value) && internalValue.value.length === 0)
    )

    const useNativeSelect = computed(() => isMobile.value || isTablet.value)

    function mapToOption(unmappedOption: any): Option {
      return {
        ...unmappedOption,
        text: unmappedOption[props.textField],
        value: unmappedOption[props.valueField],
        count: unmappedOption.count
      }
    }

    function clearSearchInput() {
      q.value = ''
    }

    function handleSearch(text: string) {
      q.value = text
      emit('searchChange', text)
    }

    function handleMultiSelect(value: any) {
      emitValue(value)
    }

    function emitValue(value: any) {
      emit('input', value)
      emit('update:modelValue', value)
      emit('change', value)
    }

    function handleSelect(value: any) {
      if (disabledOptions.value.has(value)) {
        return
      }

      emitValue(value)
      internalDropdownOpen.value = false
    }

    function onDropdownShow() {
      internalDropdownOpen.value = true
      emit('show')
    }

    function onDropdownHidden() {
      internalDropdownOpen.value = false
      emit('hide')
    }

    function onClearAll() {
      emitValue([])
    }

    function onRemoveOption(option: Option) {
      emitValue(internalValue.value.filter((v: any) => v !== option.value))
    }

    return {
      q,
      internalValue,
      internalOptions,
      selectedOptions,
      filteredOptions,
      internalDropdownOpen,
      nothingSelected,
      customSelectRef,
      filteredOptionGroups,
      useNativeSelect,
      onClearAll,
      onRemoveOption,
      handleSelect,
      clearSearchInput,
      handleSearch,
      handleMultiSelect,
      onDropdownShow,
      onDropdownHidden
    }
  }
})
